/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
// import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';

import { DonutChart } from '../elements/DonutChart';
import bg2 from '../../../assets/images/background/bg2.jpg';
import logowhite from '../../../assets/images/LPT_logo_light.png';

export const ComingSoon = () => {
  const [timerDays, setTimerDays] = useState('00');
  const [timerHours, setTimerHours] = useState('00');
  const [timerMinutes, setTimerMinutes] = useState('00');
  const [timerSeconds, setTimerSeconds] = useState('00');
  let interval: any = useRef();
  const startTimer = () => {
    /* Website Launch Date */
    let WebsiteLaunchDate = new Date();
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    WebsiteLaunchDate.setMonth(WebsiteLaunchDate.getMonth() + 1);
    WebsiteLaunchDate = new Date(
      `${WebsiteLaunchDate.getDate()} ${monthNames[WebsiteLaunchDate.getMonth()]} ${WebsiteLaunchDate.getFullYear()}`,
    );
    /* Website Launch Date END */

    const countdownDate = new Date('Dec 31, 2022 00:01:00').getTime();
    // const countdownDate = new Date(WebsiteLaunchDate+' 23:5').getTime();
    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countdownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        // stop our timer
        clearInterval(interval);
      } else {
        // update timer
        setTimerDays(days.toString());
        setTimerHours(hours.toString());
        setTimerMinutes(minutes.toString());
        setTimerSeconds(seconds.toString());
      }
    }, 1000);
  };

  // componentDidMount
  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval);
    };
  });

  // Email
  const form = useRef<any>();
  const sendEmail = (e: any) => {
    e.preventDefault();
    alert('email sent');
    // // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
    // emailjs.sendForm('service_gfykn6i', 'template_iy1pb0b', e.target, 'HccoOtZS6GHw-N-m6').then(
    //   (result) => {
    //     console.log(result.text);
    //   },
    //   (error) => {
    //     console.log(error.text);
    //   },
    // );
    // e.target.reset();
  };
  return (
    <div className="dez-coming-soon style-3">
      <div className="bg-img" style={{ backgroundImage: `url(${bg2})` }} />
      <div className="clearfix dez-coming-bx">
        <div className="dez-content">
          <div className="center-md">
            <div className="logo top-left m-b30">
              <Link to="/">
                <img src={logowhite} alt="" />
              </Link>
            </div>
            <h2 className="dez-title mb-2">COMING SOON</h2>
            <p className="text-white">Our website is coming soon, follow us for update now!</p>

            <form className="dzSubscribe" ref={form} onSubmit={sendEmail}>
              <div className="ft-subscribe">
                <div className="dzSubscribeMsg text-white" />
                <div className="ft-row m-b15">
                  <input
                    name="dzEmail"
                    required
                    type="email"
                    placeholder="Enter Email Address"
                    className="form-control"
                  />
                  <button name="submit" value="Submit" type="submit" className="btn">
                    <i className="fas fa-arrow-right" />
                  </button>
                </div>
                <p className="text-white">And don’t worry, we hate spam too! You can unsubcribe at anytime.</p>
              </div>
            </form>

            <div className="countdown-timer countdown-container">
              <div className="clock">
                <div className="clock-item clock-days countdown-time-value">
                  <div className="clock-canvas">
                    <DonutChart
                      value={timerDays}
                      backgroundColor="rgba(255,255,255,1)"
                      backgroundColor2="rgba(255, 255, 255,0.4)"
                    />
                  </div>
                  <div className="text">
                    <p className="val">{timerDays}</p>
                    <p className="type-days type-time" data-border-color="#fff">
                      Days
                    </p>
                  </div>
                </div>
                <div className="clock-item clock-hours countdown-time-value">
                  <div className="clock-canvas">
                    <DonutChart
                      value={timerHours}
                      backgroundColor="rgba(255,255,255,1)"
                      backgroundColor2="rgba(255, 255, 255,0.4)"
                    />
                  </div>
                  <div className="text">
                    <p className="val">{timerHours}</p>
                    <p className="type-hours type-time" data-border-color="#fff">
                      Hours
                    </p>
                  </div>
                </div>
                <div className="clock-item clock-minutes countdown-time-value">
                  <div id="canvas-minutes" className="clock-canvas">
                    <DonutChart
                      value={timerMinutes}
                      backgroundColor="rgba(255,255,255,1)"
                      backgroundColor2="rgba(255, 255, 255,0.4)"
                    />
                  </div>
                  <div className="text">
                    <p className="val">{timerMinutes}</p>
                    <p className="type-minutes type-time" data-border-color="#fff">
                      Minutes
                    </p>
                  </div>
                </div>
                <div className="clock-item clock-seconds countdown-time-value">
                  <div id="canvas-seconds" className="clock-canvas">
                    <DonutChart
                      value={timerSeconds}
                      backgroundColor="rgba(255,255,255,1)"
                      backgroundColor2="rgba(255, 255, 255,0.4)"
                    />
                  </div>
                  <div className="text">
                    <p className="val">{timerSeconds}</p>
                    <p className="type-seconds type-time" data-border-color="#fff">
                      Seconds
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top-right">
          <ul className="dez-social-icon">
            <li>
              <Link to="#" className="fab fa-facebook-f me-2" />
            </li>
            <li>
              <Link to="#" className="fab fa-twitter me-2" />
            </li>
            <li>
              <Link to="#" className="fab fa-linkedin-in me-2" />
            </li>
            <li>
              <Link to="#" className="fab fa-instagram" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ComingSoon;
