import CountUp from 'react-countup';
import styled from 'styled-components';
import { AppContainer } from 'common/ui/elements/AppContainer';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import Smiley from '../../../assets/images/smiley.png';
import Writer from '../../../assets/images/writer.png';
import Megaphone from '../../../assets/images/megaphone.png';
import Book from '../../../assets/images/open-book.png';

interface CounterItem {
  title: string;
  maxCount: number;
  icon: string;
}

const counters: CounterItem[] = [
  {
    title: 'Clients satisfaits.',
    maxCount: 1150,
    icon: Smiley,
  },
  {
    title: 'Différents auteurs.',
    maxCount: 190,
    icon: Writer,
  },
  {
    title: 'Pourcentage de réductions.',
    maxCount: 50,
    icon: Megaphone,
  },
  {
    title: 'Collections de articles.',
    maxCount: 2200,
    icon: Book,
  },
];

export const CounterSection = () => {
  const rStyles = {
    container: {
      padding: `${rSize(30)}px 0`,
    },
    separator: {
      height: rSize(1),
    },
    row: {
      rowGap: rSize(10),
    },
    fayCounterItem: {
      width: rSize(300),
      minHeight: rSize(100),
      gap: rSize(30),
      borderRadius: rSize(6),
      padding: rSize(20),
    },
    iconSize: {
      width: rSize(50),
    },
    textSide: {
      fontSize: rSize(20),
    },
    counterTitle: {
      fontSize: rSize(15),
    },
  };
  return (
    <AppContainer>
      <Wrapper>
        <div className="separator" style={{ ...rStyles.separator }} />
        <div className="container" style={{ ...rStyles.container }}>
          <div className="row justify-content-center" style={{ ...rStyles.row }}>
            {counters.map((counter, i) => (
              <div className="col-lg-3 col-sm-6 mb-3 d-flex justify-content-center" key={i}>
                <div
                  key={`${counter.title}-counter-key-${i}`}
                  className="fay-counter-item"
                  style={{ ...rStyles.fayCounterItem }}
                >
                  <img src={counter.icon} alt={counter.title} style={{ ...rStyles.iconSize }} />
                  <div className="text-side" style={{ ...rStyles.textSide }}>
                    <CountUp end={counter.maxCount} separator="," />
                    <div className="counter-title" style={{ ...rStyles.counterTitle }}>
                      {counter.title}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="separator" style={{ ...rStyles.separator }} />
      </Wrapper>
    </AppContainer>
  );
};

const Wrapper = styled.section`
  padding: 40px 0;
  .container {
    padding: 50px 0;
  }
  .separator {
    height: 1px;
    background-color: lightgrey;
    width: 90%;
    margin: 0 auto;
  }

  .fay-counter-item {
    width: 20vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    gap: 30px;
    border-radius: 10px;
    padding: 20px;
    background-color: var(--mono-grey-3);
  }
  .text-side {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    height: 100%;
    font-weight: 800;
    .counter-title {
      font-size: 15px;
      color: grey;
    }
  }
`;
