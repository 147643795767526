/* eslint-disable no-restricted-globals */
import styled from 'styled-components';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { formatDate } from '../../../configuration/helpers/HelperFunctions';
import { Product } from '../../../generalContext/domain/entities/Product';
import { SubCategory } from '../../../generalContext/domain/entities/SubCategory';
import { CategoriesTr } from '../../../generalContext/ui/ProductDetails/components/CategoriesTr';

interface ProductDetailsTableProps {
  product: Product;
  subCategories: SubCategory[];
}
const showNumberField = (val: string | number | undefined) => !!val && !isNaN(+val) && +val !== 0;

export const ProductDetailsTable = ({ product, subCategories }: ProductDetailsTableProps) => {
  const rStyles = {
    global: {
      p: {
        fontSize: rSize(18),
      },
    },
  };
  return (
    <Wrapper>
      <table className="table border book-overview">
        <tbody>
          <tr>
            <th style={{ ...rStyles.global.p }}>Ean13</th>
            <td style={{ ...rStyles.global.p }}>{product?.Art_Ean13}</td>
          </tr>
          {product?.Art_Auteur && (
            <tr>
              <th style={{ ...rStyles.global.p }}>Auteur</th>
              <td style={{ ...rStyles.global.p }}>{product?.Art_Auteur}</td>
            </tr>
          )}
          {product?.Art_Editeur && (
            <tr>
              <th style={{ ...rStyles.global.p }}>Editeur</th>
              <td style={{ ...rStyles.global.p }}>{product?.Art_Editeur}</td>
            </tr>
          )}
          {showNumberField(product?.Art_NmbrPages) && (
            <tr>
              <th style={{ ...rStyles.global.p }}>Nombre de pages</th>
              <td style={{ ...rStyles.global.p }}>{product?.Art_NmbrPages}</td>
            </tr>
          )}
          {showNumberField(product?.Art_Poids) && (
            <tr>
              <th style={{ ...rStyles.global.p }}>Poids</th>
              <td style={{ ...rStyles.global.p }}>{product?.Art_Poids}</td>
            </tr>
          )}
          {showNumberField(product?.Art_Hauteur) && (
            <tr>
              <th style={{ ...rStyles.global.p }}>Hauteur</th>
              <td style={{ ...rStyles.global.p }}>{product?.Art_Hauteur} cm</td>
            </tr>
          )}
          {showNumberField(product?.Art_Largeur) && (
            <tr>
              <th style={{ ...rStyles.global.p }}>Largeur</th>
              <td style={{ ...rStyles.global.p }}>{product?.Art_Largeur} cm</td>
            </tr>
          )}
          {showNumberField(product?.Art_Epaisseur) && (
            <tr>
              <th style={{ ...rStyles.global.p }}>Epaisseur</th>
              <td style={{ ...rStyles.global.p }}>{product?.Art_Epaisseur} cm</td>
            </tr>
          )}
          {product?.Art_DateParu && (
            <tr>
              <th style={{ ...rStyles.global.p }}>Première édition</th>
              <td style={{ ...rStyles.global.p }}>{formatDate(product?.Art_DateParu)}</td>
            </tr>
          )}
          {subCategories && product && <CategoriesTr product={product} subCategories={subCategories} />}
        </tbody>
      </table>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
