import { useRef, useState } from 'react';
import { DispatchAsyncResult } from 'react-redux-dispatch-async/lib/dispatchAsync';
import styled from 'styled-components';
import { MagasinState } from 'generalContext/domain/entities/Magasin';
import { useNotificationContext } from 'common/configuration/notificationContextProvider';
import { EMAIL_TEXT_CONTACT_FORM, ERROR_SEND_FORM, SUCCESS_MESSAGE_SEND } from 'configuration/helpers/constants';
import { emptyMailObj, MailObj } from 'generalContext/domain/entities/MailObj';
import { rSize } from 'core/util/ResponsiveSizeAdapter';
import { AppContainer } from 'common/ui/elements/AppContainer';
// import bg2 from '../../../assets/images/background/bg2.jpg';
import { PageTitle } from '../../../common/ui/layouts/PageTitle';

interface ContactProps {
  stateMagasins: MagasinState | undefined;
  sendEmailAsync(data: MailObj): Promise<DispatchAsyncResult<void>>;
}
export const ContactDumb = ({ stateMagasins, sendEmailAsync }: ContactProps) => {
  const form = useRef<any>();
  const notifications = useNotificationContext();
  const [IsSending, setIsSending] = useState<boolean>(false);
  const [contactObj, setContactObj] = useState<MailObj>(emptyMailObj);

  const rStyles = {
    global: {
      h3: { fontSize: rSize(26) },
      h5: { fontSize: rSize(22) },
      h6: { fontSize: rSize(20) },
      p: { fontSize: rSize(18) },
      icons: { size: rSize(50) },
    },
    wrapper: {
      margin: `${rSize(40)}px 0`,
    },
    contactMap: {
      rowGap: rSize(60),
    },
  };

  const sendEmail = async (e: any) => {
    e.preventDefault();
    try {
      setIsSending(true);
      const response: any = await sendEmailAsync({ ...contactObj, subject: EMAIL_TEXT_CONTACT_FORM });
      if (response.success) {
        notifications.setMessage({
          message: SUCCESS_MESSAGE_SEND,
          status: 'success',
        });
      }
    } catch (error) {
      notifications.setMessage({
        message: ERROR_SEND_FORM,
        status: 'error',
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <AppContainer>
      <Wrapper style={{ ...rStyles.wrapper }}>
        <div className="page-content">
          <PageTitle pageTitle="Contact" />
          <div className="content-inner-2 pt-0">
            <div className="map-iframe">
              <iframe
                title="contact-map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10075.59033020545!2d4.3568611!3d50.8515808!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c381a2a9bacd%3A0x8039a2cda11c2e0c!2sLibrairie%20Polytechnique%20(Librairie%20scolaire)!5e0!3m2!1sen!2sfr!4v1694793171617!5m2!1sen!2sfr"
                style={{
                  border: '0',
                  width: '100%',
                  minHeight: '100%',
                }}
                loading="lazy"
                allowFullScreen
              />
            </div>
          </div>
          <section className="contact-wraper1 fay-contact-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="contact-info">
                    <div className="section-head text-white style-1">
                      <h3 className="title text-white" style={{ ...rStyles.global.h3 }}>
                        Reste en contact
                      </h3>
                      <p style={{ ...rStyles.global.p }}>
                        Si vous êtes intéressé par nos produits mais que vous ne savez pas quoi faire, veuillez nous
                        contacter.
                      </p>
                    </div>
                    <ul className="no-margin">
                      <li className="icon-bx-wraper text-white left m-b30">
                        <div className="icon-md">
                          <span className="icon-cell text-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={rStyles.global.icons.size}
                              height={rStyles.global.icons.size}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-map-pin"
                            >
                              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                              <circle cx="12" cy="10" r="3" />
                            </svg>
                          </span>
                        </div>
                        <div className="icon-content">
                          <h5 className=" dz-tilte text-white" style={{ ...rStyles.global.h5 }}>
                            Notre adresse
                          </h5>
                          <p style={{ ...rStyles.global.p }}>{stateMagasins?.current?.magasinAddress ?? '-'}</p>
                        </div>
                      </li>
                      <li className="icon-bx-wraper text-white left m-b30">
                        <div className="icon-md">
                          <span className="icon-cell text-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={rStyles.global.icons.size}
                              height={rStyles.global.icons.size}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-mail"
                            >
                              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                              <polyline points="22,6 12,13 2,6" />
                            </svg>
                          </span>
                        </div>
                        <div className="icon-content">
                          <h5 className="dz-tilte text-white" style={{ ...rStyles.global.h5 }}>
                            Notre Email
                          </h5>
                          <p style={{ ...rStyles.global.p }}>{stateMagasins?.current?.magasinEmail ?? '-'}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-7 m-b40">
                  <div className="contact-area1 m-r20 m-md-r0">
                    <div className="section-head style-1">
                      <h6 className="sub-title text-primary" style={{ ...rStyles.global.h6 }}>
                        CONTACTEZ-NOUS
                      </h6>
                      <h3 className="title m-b20" style={{ ...rStyles.global.h3 }}>
                        Prenez contact avec nous
                      </h3>
                    </div>
                    <form className="dz-form dzForm" ref={form} onSubmit={sendEmail}>
                      <div className="input-group">
                        <input
                          required
                          type="text"
                          className="form-control"
                          style={{ ...rStyles.global.p }}
                          placeholder="Nom et prénom"
                          value={contactObj.name}
                          onChange={(e) => setContactObj((prevVal) => ({ ...prevVal, name: e.target.value }))}
                        />
                      </div>
                      <div className="input-group">
                        <input
                          required
                          type="text"
                          className="form-control"
                          style={{ ...rStyles.global.p }}
                          placeholder="Email"
                          value={contactObj.email}
                          onChange={(e) => setContactObj((prevVal) => ({ ...prevVal, email: e.target.value }))}
                        />
                      </div>
                      <div className="input-group">
                        <input
                          required
                          type="text"
                          className="form-control"
                          style={{ ...rStyles.global.p }}
                          placeholder="Téléphone"
                          value={contactObj.phone}
                          onChange={(e) => setContactObj((prevVal) => ({ ...prevVal, phone: e.target.value }))}
                        />
                      </div>
                      <div className="input-group">
                        <textarea
                          required
                          rows={5}
                          className="form-control"
                          style={{ ...rStyles.global.p }}
                          placeholder="Message"
                          value={contactObj.message}
                          onChange={(e) => setContactObj((prevVal) => ({ ...prevVal, message: e.target.value }))}
                        />
                      </div>
                      <div>
                        <button
                          disabled={IsSending}
                          name="submit"
                          type="submit"
                          value="submit"
                          className="btn w-100 btn-primary btnhover"
                          style={{ ...rStyles.global.p }}
                        >
                          ENVOYER
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Wrapper>
    </AppContainer>
  );
};

const Wrapper = styled.div`
  .contact-wraper1 .col-lg-5 {
    color: white;
  }
  .fay-contact-wrapper {
    background: var(--secondary);
  }
`;
